import React from 'react'
import ReactCardFlip from 'react-card-flip'
import { link } from './LocationContext'

export default ({ title, author, img,  showRead, inRange, inMinDistance, active, idx, setIdx, sizeOverride, backSizeOverride, authorSizeOverride, titleSizeOverride, ctaSizeOverride, togglePadding, cardRatio }) => {
    return (
    <div className={`relative grid place-items-center ${sizeOverride || `w-[260px] h-[420px]`}`} onClick={_ => setIdx(idx) }>
        <ReactCardFlip isFlipped={active} containerStyle={{height:"100%",minWidth:"100%",maxWidth:"100%"}}>
            <div className={`grid place-items-center w-full h-full bg-[#1e1e1e] ${backSizeOverride || `w-[260px] h-[420px]`}`}><img src={img}  /></div>
            <div className={`${backSizeOverride || `w-[260px] h-[420px]`} ${cardRatio} relative flex flex-col justify-between inset-0 bgc-grey text-black`}>
                <div className={`absolute grid ${cardRatio} px-4 ${togglePadding ? `p-4`: `p-6`}`}>
                    <div className={`ff-pilat ${authorSizeOverride || `text-base`} leading-none mb-2 lg:mb-0`}>{author}</div>
                    <div className={`ff-pilat-wide ${titleSizeOverride || `text-lg`} leading-none uppercase`}>{title}</div>
                    { showRead && <div className='flex align-end'><a href={link()} target="_blank" className={`h-min-content ff-pilat-wide grid place-items-center bg-black c-green w-full uppercase py-4 text-center ${ctaSizeOverride || 'lg:text-base'}`}>Read Book In App</a></div>}
                </div>
            </div>
        </ReactCardFlip>
    </div>
    )
}