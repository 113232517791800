import React, { useEffect, useRef, useState } from 'react'
import BaseWrap from './BaseWrap'
import gsap from 'gsap'
import ScrollTrigger from "gsap/ScrollTrigger"
import Collapsed from './Collapsed'
import Policy from './Policy'

export default _ => {
    gsap.registerPlugin(ScrollTrigger)
    const ref = useRef(null)
    const tl = useRef(null)
    const [showPolicy, setShowPolicy] = useState(false)
    
    return (
    <div className="">
        <BaseWrap id="faq" fwdRef={ref} className={'py-16'}  styleProps={{backgroundImage:'url("/background-texture.jpg"',backgroundSize:"contain"}}>
            <div className='no-pad'>

                <div className="relative mb-4 lg:mb-8">
                    <div className="ff-pilat-wide text-lg md:text-xl lg:text-2xl">FAQs</div>
                </div>
                <div className="relative mb-4">
                    <div className="ff-pilat text-base md:text-[1.2rem] lg:text-lg uppercase">What is the Banned Book Club? </div>
                </div>
                <div className="relative mb-4">
                    <div className='ff-spacemono lg:text-base [1.4rem]'>The Banned Book Club is an effort to ensure all readers have access to all the books they want to read. To that end, the Banned Book Club is making banned books available to readers in the locations across the United States where titles have been banned. The banned books are available to readers for free from the Palace e-reader app.
</div>
                </div>
                <div className="relative mb-4">
                    <div className="ff-pilat text-base md:text-[1.2rem] lg:text-lg uppercase">Who is doing this? </div>
                </div>
                <div className="relative mb-4">
                    <div className='ff-spacemono lg:text-base [1.4rem]'><a href="https://dp.la/" target='_blank'>Digital Public Library of America</a> (DPLA) has partnered with <a href="https://www.lyrasis.org/" target='_blank'>Lyrasis</a> and <a href="http://thepalaceproject.org/" target="_blank" rel="noopener noreferrer">The Palace Project</a> to provide this service.</div>
                </div>
                <Collapsed id={'whoWeAre'} className={''}>

                <div className="relative mb-4">
                    <div className="ff-pilat text-base md:text-[1.2rem] lg:text-lg uppercase">How does this work? </div>
                </div>
                <div className="relative mb-4">
                    <div className='ff-spacemono lg:text-base [1.4rem]'>In locations where books have been banned, The Banned Book Club offers free access to those books through the free Palace e-reader app. To get started, follow these four easy steps: 
                    <ul>
                        <li>Download the free Palace Project app for <a href="https://apps.apple.com/us/app/the-palace-project/id1574359693#?platform=iphone" target="_blank" rel="noopener noreferrer">iOS</a> or <a href="https://play.google.com/store/apps/details?id=org.thepalaceproject.palace" target="_blank" rel="noopener noreferrer">Android</a></li>
                        <li>Open the app and select “Banned Book Club”</li>
                        <li>Browse the catalog and select a title you would like to read by tapping the title and clicking “Get”</li>
                        <li>Sign up for your own virtual library card by clicking on “Create Card” and walking through the steps</li>
                    </ul>
                    </div>
                </div>

                <div className="relative mb-4">
                    <div className="ff-pilat text-base md:text-[1.2rem] lg:text-lg uppercase">What is DPLA? </div>
                </div>
                <div className="relative mb-4">
                    <div className='ff-spacemono lg:text-base [1.4rem]'>DPLA stands for Digital Public Library of America. DPLA is a national non-profit organization with a mission to empower people to learn, grow, and contribute to a diverse and better-functioning society by maximizing access to our shared history, culture, and knowledge.</div>
                </div>

                <div className="relative mb-4">
                    <div className="ff-pilat text-base md:text-[1.2rem] lg:text-lg uppercase">What is Lyrasis? </div>
                </div>
                <div className="relative mb-4">
                    <div className='ff-spacemono lg:text-base [1.4rem]'>Lyrasis is a community-focused, mission-driven nonprofit that partners with archives, libraries, museums and research communities to ensure enduring access to our shared academic, scientific and cultural heritage. Home to The Palace Project, BiblioLabs, DSpace, ArchivesSpace and other community-supported programs, Lyrasis offers technologies and services to help knowledge professionals and collections-holding organizations worldwide foster collaboration, solve problems and build solutions to benefit the wider community.</div>
                </div>

                <div className="relative mb-4">
                    <div className="ff-pilat text-base md:text-[1.2rem] lg:text-lg uppercase">What is The Palace Project? </div>
                </div>
                <div className="relative mb-4">
                    <div className='ff-spacemono lg:text-base [1.4rem]'>The Palace Project is a new, non-profit platform that puts libraries at the forefront of the e-content landscape. Palace supports the mission of libraries by providing equitable access to digital knowledge, bolstering the relationship between libraries and patrons, protecting patron privacy, and enabling libraries to serve all their e-content in a single app. Funded by a large investment from the John S. and James L. Knight Foundation, The Palace Project is a division of Lyrasis working in strategic partnership with Digital Public Library of America.  </div>
                </div>

                <div className="relative mb-4">
                    <div className="ff-pilat text-base md:text-[1.2rem] lg:text-lg uppercase">How do I access the Palace app? </div>
                </div>
                <div className="relative mb-4">
                    <div className='ff-spacemono lg:text-base [1.4rem]'>The Palace app is available for free download for <a href="https://apps.apple.com/us/app/the-palace-project/id1574359693#?platform=iphone" target="_blank" rel="noopener noreferrer">iOS</a> or <a href="https://play.google.com/store/apps/details?id=org.thepalaceproject.palace" target="_blank" rel="noopener noreferrer">Android</a>. Search “Palace Project” in either the Apple App Store and Google Play Store to download the app to your device. </div>
                </div>

                <div className="relative mb-4">
                    <div className="ff-pilat text-base md:text-[1.2rem] lg:text-lg uppercase">What titles are available? </div>
                </div>
                <div className="relative mb-4">
                    <div className='ff-spacemono lg:text-base [1.4rem]'>The Banned Book Club strives to make all books that have been banned (removed from library shelves) across the United States available in the collection that is available in the Palace app. The list of banned books comes from data maintained by Dr. Tasslyn Magnusson at EveryLibrary. Tasslyn also supports the work of the Freedom to Read Team at PEN America and their Index of School Book Bans.</div>
                </div>

                <div className="relative mb-4">
                    <div className="ff-pilat text-base md:text-[1.2rem] lg:text-lg uppercase">Can I borrow other books that have not been banned? </div>
                </div>
                <div className="relative mb-4">
                    <div className='ff-spacemono lg:text-base [1.4rem]'>Readers can also access more than 25,000 freely licensed ebooks by choosing “Palace Bookshelf” as your library in the Palace app or <a href="http://palacebookshelf.dp.la/" target="_blank" rel="noopener noreferrer">browsing here</a>. If your library uses the Palace app, you can also choose your library to access books from your library's collection.</div>
                </div>

                <div className="relative mb-4">
                    <div className="ff-pilat text-base md:text-[1.2rem] lg:text-lg uppercase">How do I get started?</div>
                </div>
                <div className="relative mb-4">
                    <div className='ff-spacemono lg:text-base [1.4rem]'>To get started, follow these four easy steps: 
                    <ul>
                        <li>Download the free Palace Project app for <a href="https://apps.apple.com/us/app/the-palace-project/id1574359693#?platform=iphone" target="_blank" rel="noopener noreferrer">iOS</a> or <a href="https://play.google.com/store/apps/details?id=org.thepalaceproject.palace" target="_blank" rel="noopener noreferrer">Android</a></li>
                        <li>Open the app and select “Banned Book Club”</li>
                        <li>Browse the catalog and select a title you would like to read by tapping the title and clicking “Get”</li>
                        <li>Sign up for your own virtual library card by clicking on “Create Card” and walking through the steps</li>
                    </ul>
                    </div>
                </div>

                <div className="relative mb-4">
                    <div className="ff-pilat text-base md:text-[1.2rem] lg:text-lg uppercase">How many books can I access? </div>
                </div>
                <div className="relative mb-4">
                    <div className='ff-spacemono lg:text-base [1.4rem]'>The number of books available in The Banned Book Club is ever-changing as the goal is to provide access to each banned book as it is banned across the United States.</div>
                </div>

                <div className="relative mb-4">
                    <div className="ff-pilat text-base md:text-[1.2rem] lg:text-lg uppercase">How do I get a virtual library card?</div>
                </div>
                <div className="relative mb-4">
                    <div className='ff-spacemono lg:text-base [1.4rem]'>Follow the steps below to access The Banned Book Club in the Palace app: <br /><br />
                    <div className="flex flex-row">
                        <div className='min-w-[5rem]'><strong>Step 1:</strong></div><div>Search for “The Palace Project” in the App Store or Google Play store and download it.</div>
                    </div>
                    <div className="flex flex-row">
                        <div className='min-w-[5rem]'><strong>Step 2:</strong></div><div>Search for “Banned Book Club.”</div>
                    </div>
                    <div className="flex flex-row">
                        <div className='min-w-[5rem]'><strong>Step 3:</strong></div><div>Click on the Settings gear icon in the lower right corner.</div>
                    </div>
                    <div className="flex flex-row">
                        <div className='min-w-[5rem]'><strong>Step 4:</strong></div><div>Click on Libraries, then Banned Book Club.</div>
                    </div>
                    <div className="flex flex-row">
                        <div className='min-w-[5rem]'><strong>Step 5:</strong></div><div>At the login screen, Click on Create Card.</div>
                    </div>
                    <div className="flex flex-row">
                        <div className='min-w-[5rem]'><strong>Step 6:</strong></div><div>Click “Allow Once” when prompted to allow Palace to use your location.</div>
                    </div>
                    <div className="flex flex-row">
                        <div className='min-w-[5rem]'><strong>Step 7:</strong></div><div>Follow the instructions to create your virtual library card.</div>
                    </div>
                    <div className="flex flex-row">
                        <div className='min-w-[5rem]'><strong>Step 8:</strong></div><div>Check your email and click the verification link.</div>
                    </div>
                    <div className="flex flex-row">
                        <div className='min-w-[5rem]'><strong>Step 9:</strong></div><div>Return to the Palace app and log in using the library barcode number found in the verification email and the password you created.</div>
                    </div>
                    <div className="flex flex-row">
                        <div className='min-w-[5rem]'><strong>Step 10:</strong></div><div>Download your first book!</div>
                    </div>
                    
                    </div>
                </div>

                <div className="relative mb-4">
                    <div className="ff-pilat text-base md:text-[1.2rem] lg:text-lg uppercase">How many books can I check out at one time?</div>
                </div>
                <div className="relative mb-4">
                    <div className='ff-spacemono lg:text-base [1.4rem]'>You can check out up to five titles from The Banned Book Club. You can also reserve up to five titles that may be currently checked out by another user.</div>
                </div>

                <div className="relative mb-4">
                    <div className="ff-pilat text-base md:text-[1.2rem] lg:text-lg uppercase">For how long can I check out books? </div>
                </div>
                <div className="relative mb-4">
                    <div className='ff-spacemono lg:text-base [1.4rem]'>Each book can be checked out for 21 days.</div>
                </div>

                <div className="relative mb-4">
                    <div className="ff-pilat text-base md:text-[1.2rem] lg:text-lg uppercase">Are all banned books available? If not, why now? </div>
                </div>
                <div className="relative mb-4">
                    <div className='ff-spacemono lg:text-base [1.4rem]'>We strive to make as many of the books that have been banned available as possible, within our collection development policy, but it has not been possible to source every single titles. Some of the books, especially children's books, are not available in eBook form, and some are published as eBooks but are not yet available through the Palace Marketplace.  We will work to add books on a rolling basis as more become available. </div>
                </div>

                <div className="relative mb-4">
                    <div className="ff-pilat text-base md:text-[1.2rem] lg:text-lg uppercase">Will new books be added? </div>
                </div>
                <div className="relative mb-4">
                    <div className='ff-spacemono lg:text-base [1.4rem]'>The Banned Book Club will be updated with new books that have been banned approximately once a quarter (every three months). </div>
                </div>

                <div className="relative mb-4">
                    <div className="ff-pilat text-base md:text-[1.2rem] lg:text-lg uppercase">How much does this cost?</div>
                </div>
                <div className="relative mb-4">
                    <div className='ff-spacemono lg:text-base [1.4rem]'>This service is completely free for readers. </div>
                </div>

                <div className="relative mb-4">
                    <div className="ff-pilat text-base md:text-[1.2rem] lg:text-lg uppercase">Who is paying for this? </div>
                </div>
                <div className="relative mb-4">
                    <div className='ff-spacemono lg:text-base [1.4rem]'>DPLA and Lyrasis are funding the book licenses. </div>
                </div>

                <div className="relative mb-4">
                    <div className="ff-pilat text-base md:text-[1.2rem] lg:text-lg uppercase">Are there age restrictions?</div>
                </div>
                <div className="relative mb-4">
                    <div className='ff-spacemono lg:text-base [1.4rem]'>You must be at least 13 years of age to get a virtual library card and check out ebooks. </div>
                </div>

                <div className="relative mb-4">
                    <div className="ff-pilat text-base md:text-[1.2rem] lg:text-lg uppercase">I object to one of these books. How can I let you know?</div>
                </div>
                <div className="relative mb-4">
                    <div className='ff-spacemono lg:text-base [1.4rem]'>If you object to one of the books made available in The Banned Book Club, please fill out <a href="https://docs.google.com/forms/d/e/1FAIpQLSd4PUBiz6gg2-SjpiXjOUAtoVOcL7EMTtmeK1GkWgw_o8hEYA/viewform" target='_blank'>this form</a>.</div>
                </div>

                <div className="relative mb-4">
                    <div className="ff-pilat text-base md:text-[1.2rem] lg:text-lg uppercase">There are books banned that I can't access. How can I let you know? </div>
                </div>
                <div className="relative mb-4">
                    <div className='ff-spacemono lg:text-base [1.4rem]'>If you believe there are additional books banned in your area that are not available in The Banned Book Club, please fill out <a href="https://docs.google.com/forms/d/e/1FAIpQLSd4PUBiz6gg2-SjpiXjOUAtoVOcL7EMTtmeK1GkWgw_o8hEYA/viewform" target='_blank'>this form</a>. The Banned Book Club will be updated with newly banned books every three months.</div>
                </div>

                <div className="relative mb-4">
                    <div className="ff-pilat text-base md:text-[1.2rem] lg:text-lg uppercase">Shouldn't parents be able to control the books to which their children have access? </div>
                </div>
                <div className="relative mb-4">
                    <div className='ff-spacemono lg:text-base [1.4rem]'>Of course. If you are concerned about your child reading certain books, we encourage you to talk to them about what they would like to read and why and come to a conclusion that works best for your family. You can also use The Banned Book Club to read books about which you might be concerned prior to your child reading them. Please remember that you can enable parental controls on your child's device to control and/or monitor their access to specific apps.</div>
                </div>

                <div className="relative mb-4">
                    <div className="ff-pilat text-base md:text-[1.2rem] lg:text-lg uppercase">How can I learn more about book banning?</div>
                </div>
                <div className="relative mb-4">
                    <div className='ff-spacemono lg:text-base [1.4rem]'>We recommend checking out <a href="https://uniteagainstbookbans.org/" target="_blank" rel="noopener noreferrer">UniteAgainstBookBans.org</a>, a national initiative to empower readers everywhere to stand together against censorship. The site contains information about intellectual freedom; statistics about the recent rise in book banning attempts; a parent toolkit; and recent news about efforts to stand against censorship. </div>
                </div>

                <div className="relative mb-4">
                    <div className="ff-pilat text-base md:text-[1.2rem] lg:text-lg uppercase">What is DPLA's collection policy? </div>
                </div>
                <div className="relative mb-4">
                    <div className='ff-spacemono lg:text-base [1.4rem]'>DPLA's collection development policy was developed by librarians on our Curation Corps and reviewed by many leading librarians including those on DPLA's board of directors.  You can view the whole policy <a href="#" className='underline' onClick={evt => {evt.preventDefault(); setShowPolicy(true);}}>here</a>. </div>
                </div>
                { showPolicy && <Policy setShowPolicy={setShowPolicy} /> }
                <div className="relative mb-4">
                    <div className="ff-pilat text-base md:text-[1.2rem] lg:text-lg uppercase">How do I report an issue with the Palace app? Who do I contact with patron questions?</div>
                </div>
                <div className="relative mb-4">
                    <div className='ff-spacemono lg:text-base [1.4rem]'>Please email <a href="mailto:bannedbookclubhelp@dp.la">bannedbookclubhelp@dp.la</a>. Please include any error messages where applicable. By contacting the support account you are creating a support ticket, which helps track the issue and communicate feedback. The Banned Book Book Club support team will respond and help troubleshoot any issues that arise. If The Banned Book Club support team cannot solve the issue outright, they will escalate it to the development team to be reviewed. </div>
                </div>

                <div className="relative mb-4">
                    <div className="ff-pilat text-base md:text-[1.2rem] lg:text-lg uppercase">How do I log in to the Palace app?</div>
                </div>
                <div className="relative mb-4">
                    <div className='ff-spacemono lg:text-base [1.4rem]'>After downloading the Palace app, a quick 3-step guide will appear. Click on “Find Your Library” and search for or scroll to “Banned Book Club.” Find a title you'd like to read and click “Get.” If you do not have a virtual library card, click “Create a Card” and follow the onscreen instructions. </div>
                </div>

                <div className="relative mb-4">
                    <div className="ff-pilat text-base md:text-[1.2rem] lg:text-lg uppercase">How do I reset my password if I've forgotten it?</div>
                </div>
                <div className="relative mb-4">
                    <div className='ff-spacemono lg:text-base [1.4rem]'>If you've forgotten your password, you can reset it from the settings screen. Open the Banned Book Club library in the Palace app and click on the Settings button located on the bottom right of the screen. Next, click on “Libraries,” then “Banned Book Club.” Finally, click on “Forgot your password?” </div>
                </div>

                <div className="relative mb-4">
                    <div className="ff-pilat text-base md:text-[1.2rem] lg:text-lg uppercase">Are books automatically returned at the end of their checkout period?</div>
                </div>
                <div className="relative mb-4">
                    <div className='ff-spacemono lg:text-base [1.4rem]'>If you do not return your books prior to your loans expiring, the books will automatically return on their due dates.</div>
                </div>

                <div className="relative mb-4">
                    <div className="ff-pilat text-base md:text-[1.2rem] lg:text-lg uppercase">Do items without due dates, such as titles from the Palace Bookshelf, ever automatically return?</div>
                </div>
                <div className="relative mb-4">
                    <div className='ff-spacemono lg:text-base [1.4rem]'>Titles without due dates will remain on your bookshelf until you return them or remove them. </div>
                </div>

                <div className="relative mb-4">
                    <div className="ff-pilat text-base md:text-[1.2rem] lg:text-lg uppercase">How does the Palace app promote patron privacy?</div>
                </div>
                <div className="relative mb-4">
                    <div className='ff-spacemono lg:text-base [1.4rem]'>The Palace Project will never collect or sell patron information to third parties. <a href="https://legal.palaceproject.io/Privacy Policy.html" target="_blank" rel="noopener noreferrer">The Palace app privacy policy can be found here</a>.</div>
                </div>
                
                </Collapsed>
            </div>
        </BaseWrap>
    </div>
    )
}
