import React, { useEffect, useRef } from 'react'
import BaseWrap from './BaseWrap'
import gsap from 'gsap'
import ScrollTrigger from "gsap/ScrollTrigger"
import PalaceProject from './assets/palace-project.png'
import { link } from './LocationContext'

export default _ => {
    gsap.registerPlugin(ScrollTrigger)
    const ref = useRef(null)
    const tl = useRef(null)

    useEffect(() => {
        if(tl.current) { tl.current.kill() }
        tl.current = gsap.timeline({
            scrollTrigger: {
                markers: false,
                trigger: "#whoWeAre",
                pin: true,
                start: "top top",
                end: `+=${window.innerHeight}px`,
                scrub: 1,
            }
        });

        tl.current.addLabel("start")
            .to('#whoWeAre .redacted3', { 
                webkitClipPath: 'inset(0% 105% 0% 0%)',
                clipPath: 'inset(0% 105% 0% 0%)',
                stagger: 0.1
             })
            .addLabel("hide")
            .to('#whoWeAre .redact3', { alpha: 0 })
            .addLabel("end");
        return () => {
            if(tl.current) { tl.current.kill() }
        }
    }, [ref])
    return(
            <BaseWrap id="whoWeAre" className={'bg-black bg-noise c-gray'} fwdRef={ref}>
                <div className='whoWeAre-content no-pad'>
                    <div className="relative mb-4 lg:mb-8">
                        <div className="ff-pilat-wide text-lg md:text-xl lg:text-2xl uppercase">Who We Are</div>
                        <div className="absolute top-0 redacted3 ff-pilat-wide text-lg md:text-xl lg:text-2xl uppercase">Who We Are</div>
                        <div className="absolute top-0 ff-pilat-wide text-lg md:text-xl lg:text-2xl uppercase">Who We Are</div>
                    </div>
                    <div className="relative mb-4">
                        <div className="ff-pilat uppercase text-base-2 md:text-[1.2rem] lg:text-lg leading-none">WE ARE THE DIGITAL PUBLIC LIBRARY OF AMERICA, AND OUR MISSION IS TO MAXIMIZE ACCESS TO KNOWLEDGE FOR ALL</div>
                        <div className="absolute uppercase top-0 redacted3 ff-pilat text-base-2 md:text-[1.2rem] lg:text-lg leading-none">WE ARE THE DIGITAL PUBLIC LIBRARY OF AMERICA, AND OUR MISSION IS TO MAXIMIZE ACCESS TO KNOWLEDGE FOR ALL</div>
                        <div className="absolute uppercase top-0 ff-pilat text-base-2 md:text-[1.2rem] lg:text-lg leading-none">WE ARE THE DIGITAL PUBLIC LIBRARY OF AMERICA, AND OUR MISSION IS TO MAXIMIZE ACCESS TO KNOWLEDGE FOR ALL</div>
                    </div>
                    <div className="relative mb-4">
                        <div className='ff-spacemono lg:text-base [1.4rem]'>We have watched with growing unease the trend of libraries being forced to remove books from both physical and virtual shelves. We believe that readers everywhere should be able to access to the books they want to read and in the right of people everywhere to convey their thoughts and ideas without fear of censorship. </div>
                        <div className='absolute top-0 redacted3 ff-spacemono lg:text-base [1.4rem]'>We have watched with growing unease the trend of libraries being forced to remove books from both physical and virtual shelves. We believe that readers everywhere should be able to access to the books they want to read and in the right of people everywhere to convey their thoughts and ideas without fear of censorship. </div>
                        <div className='absolute top-0 ff-spacemono lg:text-base [1.4rem]'>We have watched with growing unease the trend of libraries being forced to remove books from both physical and virtual shelves. We believe that readers everywhere should be able to access to the books they want to read and in the right of people everywhere to convey their thoughts and ideas without fear of censorship. </div>
                    </div>
                    <div className="mx-auto lg:mx-0 w-full lg:max-w-md mt-8">
                        <a href={link()} target={`_blank`} className="bgc-green ff-pilat-wide grid place-items-center text-black w-full uppercase py-4 text-base">
                            access banned books
                        </a>
                    </div>
                    <div className='mt-10 flex justify-center lg:block'>
                        <img src={PalaceProject} className='no-pad' alt="Powered by Palace Project" width="350"/>
                    </div>
                </div>
        </BaseWrap>
    )
}
