import { Wrapper, Status } from "@googlemaps/react-wrapper";
import React, { useEffect, useRef, useState, useContext } from "react";
import LocationContext, { LOC_STATES } from './LocationContext'
import styledMapType from "./MapViewStyles";
import libData from './libs.json'
import MapViewNoLibs from "./MapViewNoLibs";

const MyMapComponent = (prop) => {
  const ref = useRef();
  const ctx = useContext(LocationContext);
  const didAllowLocation = ctx.locationStatus;
  const closestLib = ctx.closestLib;
  const inRange = (closestLib.distance <= 0.061553);
  const inMinDistance = ctx.inRange; // you have to be at least 1 mile away to see a school that has banned books
  // per https://gis.stackexchange.com/questions/142326/calculating-longitude-length-in-miles 1 degree of longitude is 69 miles

  useEffect(() => {
    const map = new window.google.maps.Map(ref.current, {
      center: { lat: 39.8283, lng: -98.5795 },
      zoom: 4,
      minZoom: 4, 
      maxZoom: 20,
      disableDefaultUI: true,
      gestureHandling: "cooperative",
    });
    window.__map = map
    window.__setIO()
    //Associate the styled map with the MapTypeId and set it to display.
    map.mapTypes.set("styled_map", new window.google.maps.StyledMapType(styledMapType));
    map.setMapTypeId("styled_map");
    const icon = {
      url: `marker-green.png`, // url
      scaledSize: new window.google.maps.Size(18 * 2, 27 * 2),
      optimized: true,
    };
    libData.filter(x => x.bookCount > 0).forEach((location) => {
      const marker = new window.google.maps.Marker({
        position: { lat: location.lat, lng: location.lng },
        map: map,
        icon: icon,
        title: location.name,
      });
      marker.addListener('click', function () {
        window.__setSelected(location)
        window.__userIneracted = true
      });
      return marker;
    });
    ref.current.addEventListener('mousedown', function (e) {
      // console.log(`mousedown`)
      window.__userIneracted = true
    });
    //debugging user position
    // window.__debugMarker = new window.google.maps.Marker({
    //   position:  { lat: 39.8283, lng: -98.5795 },
    //   map: map,
    //   icon: `debug.svg`,
    //   title: `User Location`,
    // });
    return () => {
      ref.current.removeEventListener('mousedown', function (e) {
        // console.log(`mousedown`)
        window.__userIneracted = true
      });
    }
  },[]);
  return <div className="relative"><a name="theMap" /><div className="w-screen h-screen" ref={ref} id="map"></div></div>;
}
const render = (status) => {
  if (status === Status.LOADING) return <h3>{status} ..</h3>;
  if (status === Status.FAILURE) return <h3>{status} ...</h3>;
  return null;
};



const MapView = () => {
  return <Wrapper apiKey={"AIzaSyDhjUoM2yGc_VMKOnHjYrcZnbcNbmW1Wgw"} render={render}>
      <MyMapComponent/>
    </Wrapper>
}


export default MapView