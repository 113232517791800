import React, { useEffect, useRef } from 'react'
import gsap from 'gsap'
import ScrollTrigger from "gsap/ScrollTrigger"
import BaseWrap from './BaseWrap'
export default _ => {
    gsap.registerPlugin(ScrollTrigger)
    const ref = useRef(null)
    const tl = useRef(null)
    useEffect(() => {
        if(tl.current) { tl.current.kill() }
        tl.current = gsap.timeline({
            scrollTrigger: {
                trigger: "#theProblem",
                pin: true,
                start: "top top",
                end: `+=${window.innerHeight}px`,
                scrub: 1,
                markers: false
            }
        });

        tl.current.addLabel("start")
            .to('#theProblem .redacted2', {
                webkitClipPath: 'inset(0% 105% 0% 0%)',
                clipPath: 'inset(0% 105% 0% 0%)',
                stagger: 0.1
            })
            .addLabel("hide")
            .to('#theProblem .redact2', { alpha: 0 })
            .addLabel("end");
        return () => {
            if(tl.current) { tl.current.kill() }
        }
    }, [ref])


    return (
            <BaseWrap id="theProblem" className={'pb-16'}  styleProps={{backgroundImage:'url("/background-texture.jpg"',backgroundSize:"contain"}}>
                <div className="theproblem-content no-pad">
                <div className="relative mb-4 lg:mb-8">
                    <div className="ff-pilat-wide text-lg md:text-xl lg:text-2xl uppercase">The Problem</div>
                    <div className="absolute top-0 left-0 ff-pilat-wide text-lg md:text-xl lg:text-2xl redacted2 uppercase">The Problem</div>
                    <div className="absolute top-0 left-0 ff-pilat-wide text-lg md:text-xl lg:text-2xl uppercase">The Problem</div>
                </div>
                <div className="relative mb-4">
                    <div className="left-0 ff-pilat text-base-2 md:text-[1.2rem] lg:text-lg uppercase leading-none">Book Bans across the US are creating a crisis</div>
                    <div className="absolute top-0 left-0 ff-pilat text-base-2 md:text-[1.2rem] lg:text-lg redacted2 uppercase leading-none">Book Bans across the US are creating a crisis</div>
                    <div className="absolute top-0 left-0 ff-pilat text-base-2 md:text-[1.2rem] lg:text-lg uppercase leading-none">Book Bans across the US are creating a crisis</div>
                </div>
                <div className="relative">
                    <div className='ff-spacemono lg:text-base [1.4rem]'>Libraries are being forced to ban books at alarming rates. These bans are unparalleled in intensity, and censorship over certain titles has tripled since 2018. Many book bans are silencing people of color and LGBTQIA+ disproportionately. And it's time we take a stand to stop book bans altogether.</div>
                    <div className='absolute top-0 left-0 ff-spacemono lg:text-base [1.4rem] redacted2'>Libraries are being forced to ban books at alarming rates. These bans are unparalleled in intensity, and censorship over certain titles has tripled since 2018. Many book bans are silencing people of color and LGBTQIA+ disproportionately. And it's time we take a stand to stop book bans altogether.</div>
                    <div className='absolute top-0 left-0 ff-spacemono lg:text-base [1.4rem]'>Libraries are being forced to ban books at alarming rates. These bans are unparalleled in intensity, and censorship over certain titles has tripled since 2018. Many book bans are silencing people of color and LGBTQIA+ disproportionately. And it's time we take a stand to stop book bans altogether.</div>
                </div>
                </div>
            </BaseWrap>
    )
}