import React, {useState} from 'react'
import BaseWrap from './BaseWrap'
import BBCLogo from './assets/bbc-logo-gray.svg'
import Policy from './Policy'
const year = new Date().getFullYear()
const scrollUp = _ => window.scrollTo(0,0)
export default _ => {
    const [showPolicy, setShowPolicy] = useState(false)
    
    if(showPolicy) {document.querySelector('body').classList.add('lock-scroll')}
    else {document.querySelector('body').classList.remove('lock-scroll')}

    return <BaseWrap className={'bgc-black bg-noise c-gray text-center py-[25vh]'} innerClassName={'lg:min-w-md'}>
        <div className="grid place-items-center cursor-pointer mb-8" onClick={scrollUp}><img width={100} src={BBCLogo} alt="banned book club logo" /></div>
        <div className="grid justify-center mb-12 gap-8">
            <a className='ff-pilat-wide text-base uppercase leading-none' href='#' onClick={evt => 
                {
                    evt.preventDefault()
                    setShowPolicy(true)
                }}>Collection Development Policy</a>
            <a className='ff-pilat-wide text-base uppercase leading-none' href='https://dp.la/' target={`_blank`}>Digital Public Library of America</a>
        </div>
        { showPolicy && <Policy setShowPolicy={setShowPolicy} /> }
        <div className='ff-spacemono text-sm md:text-base absolute text-center right-0 left-0 bottom-10'>&copy;{year} Digital Public Library <br /> of America</div>
        <p>DPLA's ebook work is supported by the <a className="underline" href="https://knightfoundation.org/">John S. and James L. Knight Foundation</a>, the <a className="underline" href="https://www.macfound.org/">John D. and Catherine T. MacArthur Foundation</a> and the <a className="underline" href="https://sloan.org/">Alfred P. Sloan Foundation</a>.</p>
    </BaseWrap>
}