import React, {useEffect, useState} from "react";
import Arrow from './assets/arrow.svg'

export default ({id, children}) => {

    return(
    <div className="relative overflow-hidden">
        <div className="relative max-w-[80rem] m-auto overflow-visible">
            <div id={'carouselContainer' + id} className={"w-full overflow-scroll relative py-4"} style={{overflowY:"auto"}}>
                <div id={'carousel' + id} className={'grid grid-flow-col gap-4 lg:gap-10 scrollable '}>
                    {children}
                </div>
            </div>
        </div>
    </div>
    )

};