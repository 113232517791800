import React, { useEffect, useState } from "react";

export default ({ id, children, className, expandClassName }) => {
    const [collapsed, setCollapsed] = useState(true);
    const [height, setHeight] = useState("auto");

    const handleExpandCollapse = () => {
        getSectionHeight(() => {
            setCollapsed(!collapsed);
        })
    };

    const getSectionHeight = (cb) => {
        var offsetHeight = document.getElementById('collapse' + id).offsetHeight;
        setHeight(offsetHeight + "px");
        cb();
        if (!collapsed) {
            setTimeout(() => {
                setHeight("auto");
            },100)
        }
    };

    return(
        <div className={'collapse-section ' + className}>
            <div className={"collapse-section-container " + (collapsed ? '' : 'mb-4')} style={{height:(collapsed ? 0 : height)}}>
                <div className="collapse-section-content" id={'collapse' + id}>
                    {children}
                </div>
            </div>
            <div className={"relative mb-4 cursor-pointer " + (expandClassName)} onClick={handleExpandCollapse}>
                <div className="ff-pilat text-base md:text-[1.2rem] lg:text-lg uppercase">{(collapsed ? "+ See more" : "- See less")}</div>
            </div>
        </div>
    )
}