import React, {useContext} from 'react'
import BtnChevron from './BtnChevron'
import LocationContext from "./LocationContext";

export default ({active, idx, setIdx, libIdx}) => {
    const ctx = useContext(LocationContext)
    const setSelected = ctx.setSelected
    return(
        <div className={`grid justify-center items-center relative w-[260px] h-[420px] b-gray bgc-grey text-black px-4 py-6`}>
            <div className='ff-pilat-wide uppercase text-[1.2rem] align-center mt-10'>view all banned books at this library</div>
            <div className='flex self-end w-full'><button className="h-min-content ff-pilat-wide grid place-items-center bg-black c-green w-full uppercase py-4 text-base" onClick={_ => {setIdx(idx); setSelected(libIdx);} }>VIEW ALL</button></div>
        </div>
    )
}