import React from 'react'

export default ({setShowPolicy}) => {
    const closeModal = evt => { 
        evt.preventDefault()
        setShowPolicy(false)
    }
    return <div id="PolicyModal" className='fixed inset-0 bgc-black-50 z-50 c-gray'>
        <div className='cursor-pointer w-full flex justify-end px-10 my-4' id='closeBtn' onClick={closeModal}><div className=''></div></div>
        <div className="absolute inset-0 top-20 overflow-hidden pb-0">
            <div id="ModalHolder" className="w-full px-10 lg:px-0 lg:max-w-[60rem] m-auto h-full overflow-y-scroll pb-20 text-white">
            <div className="flex flex-col gap-4 px-4 text-left">
                <b>The Banned Book Club Collection Development Policy</b>
                <p><b>Introduction</b></p>
                <p>This collection development policy is a statement of guidelines used by Digital Public Library of America (DPLA) staff and volunteers in the selection, acquisition, and maintenance of ebooks for the DPLA Banned Book Club collection.</p>
                <p><b>Mission</b></p>
                <p>At DPLA, <a href="https://pro.dp.la/about-dpla-pro/strategic-plan" target='_blank' className="underline">our mission</a> is to maximize access to knowledge and information for all. We have watched with growing unease the trend of libraries being forced to remove books from both physical and virtual shelves, often titles written by or featuring people of color, Indigenous people and members of LGBTQ+ community. The Banned Book Club seeks to ensure that all American readers have access to the books they want to read, including books that reflect a diversity of experiences and viewpoints. To that end, The Banned Book Club makes copies of banned titles available in the communities where those books have been banned via the free Palace e-reader app, which is available for <a href="https://thepalaceproject.org/app/" target='_blank' className="underline">free download for iOS and Android</a>.</p>
                <p>This work and The Banned Book Club is guided and informed by the principles laid out by the American Library Association's <a href="https://www.ala.org/advocacy/intfreedom/librarybill" target='_blank' className="underline">Library Bill of Rights</a> and <a href="https://www.ala.org/advocacy/intfreedom/freedomreadstatement" target='_blank' className="underline">Freedom to Read</a> Statement.</p>
                <p><b>Selection</b></p>
                <p>We selected titles for The Banned Book Club by searching for banned and challenged books for the 2021-2022 school year through PEN America's Index of School Book Bans. This document was created by Dr. Tasslyn Magnusson and was used by DPLA with her permission.</p>
                <p>Banned titles are frequently written by or feature people of color, Indigenous people, and members of LGBTQ+ community.  The goal of The Banned Book Club is to make as many banned books as possible available, to ensure that books that reflect a diversity of experiences and viewpoints are available to all readers.</p>
                <p>Books were selected from PEN America's list of banned titles by librarians who are members of our Curation Corps, all of whom are trained librarians with MLS degrees. Additional banned books will be sourced through the American Library Association's Banned and Challenged Books webpage and from historically banned classic books that are part of the <a href="http://palacebookshelf.dp.la/" target='_blank' className="underline">Palace Bookshelf</a>, DPLA's collection of more than 16,000 open-access ebooks.</p>
                <p>Please note that we have been unable to add some banned titles to The Banned Book Club collection because they do not yet exist in ebook format or are not available in The Palace Marketplace.</p>
                <p>You can support this work by contributing <a href="https://nonprofit.resilia.com/donate?id=9847f08ebd8299a38a0b35565a97f41b50e6540c2207563a6040f88c86755dd8" target='_blank' className="underline">here</a>.</p>
            </div>

            </div>
        </div>
    </div>
}