import { React, useContext, useState, useEffect, useRef } from 'react'
import LocationContext, { LocationNotAskedFor } from './LocationContext'

export default ({ className, innerClassName, children, id, blockFeatures, shouldToggle, fwdRef, styleProps }) => {
    const ref = useRef(null)
    const ctx = useContext(LocationContext)
    const didAllowLocation = ctx.locationStatus
    const askForLocation = ctx.askForLocation
    const [buttonMessage, setButtonMessage] = useState("Share Location")
    const [style, setStyle] = useState({paddingTop:"calc(65px + 2rem)"});

    let rafIdx = -1
    useEffect(() => {
        if(styleProps) {{
            if (!Object.keys(styleProps).includes("paddingTop")) {
                setStyle({... style, ...styleProps})
            } else {
                setStyle(styleProps)
            }
        }}
    },[])
    return (
        <div id={id || (Math.random() + 1).toString(36).substring(7)} style={style} className={`relative grid place-content-center w-full px-10 py-8 min-h-screen2 ${className}`} ref={ref}>
            <div className={`base-wrap-inner m-auto max-w-[60rem] ${innerClassName || ``} ${blockFeatures && didAllowLocation == LocationNotAskedFor ? `blur-sm` : ``}`}>
                {children}
            </div>
            {blockFeatures && <div className={`blur-section-${didAllowLocation} grid place-items-center`}>
                <div className="flex flex-col items-center max-w-[65ch]">
                    <div className="c-gray ff-pilat-wide text-[1.2rem] md:text-xl lg:text-2xl uppercase px-8 pb-[10vh] text-center">allow location services to see banned books near you </div>
                    <div className="px-10 w-full lg:max-w-md">
                        <a href='#' className="ff-pilat-wide text-base grid place-items-center bgc-green text-black w-full uppercase py-4" onClick={evt => {
                            if (buttonMessage == "Loading...") return;
                            setButtonMessage("Loading...")
                            askForLocation(evt)
                        }}>{buttonMessage}</a>
                    </div>
                </div>
            </div>}
        </div>
    )
}

