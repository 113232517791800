import {React, useState, useContext} from 'react'
import BaseWrap from './BaseWrap'
import BookItem from './BookItem'
import ClickOnAnimation from './ClickOnAnimation'
import libData from './libs.json'
import LocationContext, {LOC_STATES, dedupeBooks} from './LocationContext'
import Carousel from './Carousel'
import PalaceProject from './assets/palace-project.png'

//FPO
import BookItemMore from './BookItemMore'
import getRand from './Fpo'
import MapViewNoLibs from './MapViewNoLibs'

//https://stackoverflow.com/questions/51819224/how-to-find-nearest-location-using-latitude-and-longitude-from-a-json-data

function distance(lat1, lon1, lat2, lon2, unit) {
    var radlat1 = Math.PI * lat1/180
    var radlat2 = Math.PI * lat2/180
    var theta = lon1-lon2
    var radtheta = Math.PI * theta/180
    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist)
    dist = dist * 180/Math.PI
    dist = dist * 60 * 1.1515
    if (unit=="K") { dist = dist * 1.609344 }
    if (unit=="N") { dist = dist * 0.8684 }
    return dist
  }

export default _ => {
    const [idx, setIdx] = useState(-1)
    const ctx = useContext(LocationContext)
    const closestLib = ctx.closestLib;
    const distance = ctx.distance
    const inRange = ctx.inRange
    
    const books = dedupeBooks(closestLib.books).map((x,i) => {
        return{
            key: i,
            title: x.title,
            author: x.author,
            active: false,
            showRead: ctx.inRange == LOC_STATES.LocationInRangeByLib || ctx.inRange == LOC_STATES.LocationInRangeNotByLib,
            distance: distance,
            inRange: distance <= 0.061553, //For Phase II set this to true
            inMinDistance: ctx.inRange, // you have to be at least 1 mile away to see a school that has banned books
            img: x.cover,
            backSizeOverride: 'w-full w-[260px] h-[420px]',
            cardRatio: 'card-ratio-2'
        }
    })

    const buildBookRow = (selectedIdx) => {
        const arr =  books.slice(0, 5).map((x,i) => {
            const props = {...x, active: selectedIdx == i, idx: i, setIdx: setIdx, key: i}
            return <ClickOnAnimation key={i} didAllowLocation={ctx.locationStatus}><BookItem {...props} /></ClickOnAnimation>
        })
        arr.push(<BookItemMore key={999} idx={999} setIdx={setIdx}  active={selectedIdx == 999} libIdx={closestLib}/>)
        return arr
    }

    if(ctx.inRange == LOC_STATES.LocationNotInRange)
    {
        return(
            <BaseWrap className={`bg-black bg-noise`} innerClassName={`overflow-visible`} blockFeatures shouldToggle styleProps={{paddingTop:"auto !important"}}>
                <MapViewNoLibs />
            </BaseWrap>
        )
    }

    return(
        <BaseWrap className={`bg-black bg-noise`} innerClassName={`overflow-visible`} blockFeatures shouldToggle styleProps={{paddingTop:"auto !important"}}>
            <div className="title-container">
                <h1 className="ff-pilat-wide c-gray text-lg md:text-xl lg:text-2xl uppercase mb-4 lg:mb-8 leading-none max-w-[50rem]">{closestLib.name} was forced to ban {books.length} {`book${closestLib.books.length > 1 ? `s` : ``}`}</h1>
            </div>
            <Carousel id={'Main'}>
                {buildBookRow(idx)}
            </Carousel>
            <img src={PalaceProject} alt="Powered by Palace Project" className='relative no-pad' width="350"/>
        </BaseWrap>
    )
}