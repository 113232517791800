import React, { useContext } from "react";
import BBCGray from './assets/bbc-logo-gray.svg'
import BBCBlack from './assets/bbc-logo-black.svg'


export default () => {
    const toTop = _ => window.scrollTo(0, 0)
    return (
        <div id="Header" className={`fixed w-screen z-20`}>
            <div className={`relative text-black p-4 flex justify-center lg:justify-between c-gray inset-0 bg-black bg-noise`}>
                <img className="cursor-pointer" onClick={toTop} src={BBCGray} width={30} height={30} alt="The Banned Book Club Logo" />
                <a href="https://thepalaceproject.org/app/" target="_blank" className={`self-center hidden text-base lg:block ff-pilat-wide uppercase underline`}>access banned books</a>
            </div>
            <div id="header-alt" className={`absolute c-gray inset-0 bg-black bg-noise p-4 flex justify-center lg:justify-between`}>
                <img className="cursor-pointer" onClick={toTop} src={BBCGray} width={30} height={30} alt="The Banned Book Club Logo" />
                <a href="https://thepalaceproject.org/app/" target="_blank" className={`self-center hidden text-base lg:block ff-pilat-wide uppercase underline`}>access banned books</a>
            </div>
        </div>
    )
}