import React, { useState, useEffect } from "react";
import libData from './libs.json'
export const LocationNotAskedFor = 0
export const LocationAllowed = 1
export const LocationDisallowed = 2
export const StorageKey = `locationAllowed`
export const LOC_STATES = {
  LocationUnknown : -1,
  LocationNotInRange : 0,
  LocationInRangeNotByLib : 1,
  LocationInRangeByLib : 2,
}



export const CheckLocationSavedValue = _ => {
  // Check for Geolocation support
  if (!window.navigator.geolocation) {
    console.warn('Browser does not support GeoLocation')
    window.sessionStorage.setItem(StorageKey, LocationDisallowed)
  }
  
  const setVal = window.sessionStorage.getItem(StorageKey)
  if (setVal == null) {
    window.sessionStorage.setItem(StorageKey, LocationNotAskedFor)
    return parseInt(LocationNotAskedFor)
  }
  return parseInt(setVal)
}
let watchId = null
export const AskForLocation = (evt, setLocationStatus, setCords, setClosestLib, setDistance, setInRange) => {
  if (evt) { evt.preventDefault() }
  // navigator.geolocation.getCurrentPosition( position => {
  const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0
  };
  const positionCheck = position => {
    //if user ineracts with map, stop polling
    if(window.__userIneracted) { return }
    // console.log('Got Position')
    window.sessionStorage.setItem(StorageKey, LocationAllowed)
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    setCords({ lat: latitude, lng: longitude })
    const closest = updateClosestLib({ lat: latitude, lng: longitude })
    setClosestLib(closest.lib)
    setDistance(closest.dist)
    updateLocationStatus(closest.dist, CheckLocationSavedValue(), setInRange)
    console.info({ lat: latitude, lng: longitude })
    setLocationStatus(CheckLocationSavedValue())
  }
  const errorState = _ => {
    console.error('Did not get position')
    setCords({ lat: 39.8283, lng: -98.5795 })
    window.sessionStorage.setItem(StorageKey, LocationDisallowed)
    setLocationStatus(CheckLocationSavedValue())
    updateLocationStatus(null, CheckLocationSavedValue(), setInRange)

  }

  const errorStatePoll = _ => {
    console.error('Did not get position')
    window.sessionStorage.setItem(StorageKey, LocationDisallowed)
    setLocationStatus(CheckLocationSavedValue())
    updateLocationStatus(null, CheckLocationSavedValue(), setInRange)

  }
  window.navigator.geolocation.getCurrentPosition(position => {
    //if user ineracts with map, stop polling
    if(window.__userIneracted) { return }
    //set initial 
    positionCheck(position)
    //start polling
    // console.log('Starting Watch')
    if(watchId)
    {
      window.navigator.geolocation.clearWatch(watchId)
    }
    watchId = window.navigator.geolocation.watchPosition(positionCheck, errorStatePoll, options);
  }, errorState)
}


//https://stackoverflow.com/questions/51819224/how-to-find-nearest-location-using-latitude-and-longitude-from-a-json-data
// https://www.geodatasource.com/developers/javascript
// Default is miles
const distance = (lat1, lon1, lat2, lon2, unit) => {
    var radlat1 = Math.PI * lat1/180
    var radlat2 = Math.PI * lat2/180
    var theta = lon1-lon2
    var radtheta = Math.PI * theta/180
    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist)
    dist = dist * 180/Math.PI
    dist = dist * 60 * 1.1515
    if (unit=="K") { dist = dist * 1.609344 }
    if (unit=="N") { dist = dist * 0.8684 }
    return dist
}

const updateClosestLib = (cords) => {
    // Get closest lib
    let closest = Number.MAX_VALUE;
    let closestKey = null
    let lastKey = null
    let lastDist = null
    Object.keys(libData.filter(x => x.bookCount > 0)).forEach(x => {
        const dist = distance(cords.lat, cords.lng, libData[x].lat, libData[x].lng)
        if(dist < closest) {
            closest = dist
            closestKey = x
        }
        lastKey = x //Set something for the last key
        lastDist = dist
    })

    return {lib: closestKey != null ? libData[closestKey] : libData[lastKey], 
      dist: closest != Number.MAX_VALUE ? closest: lastDist};
}

const updateLocationStatus = (distance, locationAllowed, setInRange) => {
  if(locationAllowed == LocationDisallowed) {
    setInRange(LOC_STATES.LocationNotInRange)
    return
  }
  if(locationAllowed == LocationNotAskedFor) {
    setInRange(LOC_STATES.LocationUnknown)
    return
  }
  if(distance == null) {
    setInRange(LOC_STATES.LocationUnknown)
    return
  }
  if(distance <= (0.69 * 5 )) { // Are we close
    if(distance <= 0.061553) { // Are we close enough
      setInRange(LOC_STATES.LocationInRangeByLib)
    } else { // We are close but not close enough
      setInRange(LOC_STATES.LocationInRangeByLib) // Overriding anyone within 5 miles can read book at lib
    }
    return
  } else {
    setInRange(LOC_STATES.LocationNotInRange)
    return
  }
}

const getDistance = (coords, libCords) => {
  return distance(coords.lat, coords.lng, libCords.lat, libCords.lng)
}

const LocationContext = React.createContext(LocationNotAskedFor)
export const LocationProvider = (props) => {
  const [locationStatus, setLocationStatus] = useState(CheckLocationSavedValue())
  const [cords, setCords] = useState({ lat: 39.8283, lng: -98.5795 })
  const [selection, setSelection] = useState(null)

  // Init loc
  const closest = updateClosestLib(cords)
  const [closestLib, setClosestLib] = useState(closest.lib)
  const [distance, setDistance] = useState(closest.dist)
  const [inRange, setInRange] = useState(LOC_STATES.LocationUnknown)

  const setSelected = idx => {
    setSelection(idx)
  }
  const closeModal = _ => {
    setSelected(null)
  }

  const askForLocation = evt => {
    AskForLocation(evt, setLocationStatus, setCords, setClosestLib, setDistance, setInRange)
    // console.log(cords)
  }
  useEffect(() => {
    if (locationStatus == LocationAllowed) {
      AskForLocation(null, setLocationStatus, setCords, setClosestLib, setDistance, setInRange)
    }
    window.__setSelected = setSelected
    return () => { }
  }, [locationStatus])

  useEffect(() => {
    const calcDistance = getDistance(cords, closestLib)
    window.__comm('sendUpdate', {locationStatus, cords, closestLib, distance})
  }, [locationStatus, cords, closestLib, distance])

  // Get distance
  // console.log(`distance`, distance)

  return <LocationContext.Provider value={{ locationStatus, askForLocation, cords, selection, setSelected, closeModal, closestLib, setClosestLib, distance, setDistance, inRange, setInRange }}>{props.children}</LocationContext.Provider>
}


export const link = _ => {
  if(window.navigator.userAgent.match(/iPad/i) || window.navigator.userAgent.match(/iPhone/i) || window.navigator.userAgent.match(/iPod/i)) {
      return `https://apps.apple.com/us/app/the-palace-project/id1574359693#?platform=iphone`
  }

  if(window.navigator.userAgent.match(/Android/i)) {
      return `https://play.google.com/store/apps/details?id=org.thepalaceproject.palace`
  }

  return `https://thepalaceproject.org/app/`
}

export const dedupeBooks = (books) => {
  const deduped = []
  books.forEach(x => {
    const found = deduped.find(y => y.id == x.id)
    if(found == null) {
      deduped.push(x)
    }
  })
  return deduped
}

export default LocationContext
