import { React, useState, useEffect, useContext } from 'react'
import LocationContext, { LocationNotAskedFor, link } from './LocationContext'


export default () => {
    return (
        <div className='overflow-scroll-y h-full pb-20 grid place-items-center'>
            <div className="">
                <div className='ff-pilat-wide text-lg md:text-xl lg:text-2xl mb-8 uppercase leading-none text-white'>Fortunately, there are no libraries near you that have been forced to ban books.</div>
                <h3 className='ff-pilat-wide text-base c-gray uppercase mb-4'>THAT'S NOT THE CASE FOR A LOT OF THE COUNTRY. SCROLL DOWN TO SEE AN INTERACTIVE MAP WITH EVERY LIBRARY IN THE USA THAT'S BEEN FORCED TO BAN BOOKS. BUT TO READ BANNED BOOKS, DOWNLOAD THE PALACE APP <a href={link()} target='_blank' className='underline'>HERE</a></h3>
            </div>
        </div>
    )
}