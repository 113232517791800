import { React, useState, useEffect, useContext } from 'react'
import BookItem from './BookItem'
import LocationContext, { LOC_STATES, dedupeBooks, link } from "./LocationContext";


import getRand from './Fpo'

export default () => {
    const ctx = useContext(LocationContext)
    const closeModal = ctx.closeModal
    const lib = ctx.selection
    const [idx, setIdx] = useState(-1)

    useEffect(() => {
        if(lib != null)
            document.querySelector('body').classList.add('lock-scroll')
    
      return () => {
        document.querySelector('body').classList.remove('lock-scroll')
        setIdx(-1)
        // document.querySelector('#map').scrollIntoView(true)
      }
    }, [lib])
    
    if(lib == null) return <div className='hidden'></div>

    if(lib.books.length == 0 && lib.bookCount > 0)
    {
        return (
            <div id='modal' className='fixed inset-0 bgc-black-50 z-50 c-gray'>
                <div className='cursor-pointer w-full flex justify-end px-10 my-4' id='closeBtn' onClick={closeModal}><div className=''></div></div>
                <div className="absolute inset-0 top-20 overflow-hidden pb-0">
                    <div id="ModalHolder" className="w-full px-10 lg:px-0 lg:max-w-[60rem] m-auto h-full overflow-hidden pb-20">
                        <div className='ff-pilat-wide text-lg md:text-xl lg:text-2xl mb-8 uppercase leading-none'> {lib.name} was forced to ban {lib.bookCount} {`book${lib.bookCount > 1 ? `s` : ``}`}</div>
                        <div className='overflow-scroll h-full py-20'>
                            <div className='grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-6 lg:gap-8'>
                            <div className='col-start-1'><a href={link()} target="_blank" className={`h-min-content ff-pilat-wide grid place-items-center bgc-green text-black w-full uppercase py-4 text-center lg:text-base`}>Read Books In App</a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    } 

    return <div id='modal' className='fixed inset-0 bgc-black-50 z-50 c-gray'>
        <div className='cursor-pointer w-full flex justify-end px-10 my-4' id='closeBtn' onClick={closeModal}><div className=''></div></div>
        <div className="absolute inset-0 top-20 overflow-hidden pb-0">
            <div id="ModalHolder" className="w-full px-10 lg:px-0 lg:max-w-[60rem] m-auto h-full overflow-hidden pb-20">
                <div className='ff-pilat-wide text-lg md:text-xl lg:text-2xl leading-tight mb-8 uppercase leading-none'> {lib.name} was forced to ban {dedupeBooks(lib.books).length} {`book${dedupeBooks(lib.books).length > 1 ? `s` : ``}`}</div>
                <div className='overflow-scroll h-full pb-20'>
                    <div className='grid grid-cols-2 md:grid-cols-3 gap-4 md:gap-6 lg:gap-8'>
                        {
                            dedupeBooks(lib.books).map((x, i) => {
                                return <BookItem
                                        key={i}
                                        title={x.title}
                                        author={x.author}
                                        active={i == idx}
                                        idx={i}
                                        setIdx={setIdx}
                                        img={x.cover}
                                        showRead={ctx.selection.distance <= (0.69 * 5)}
                                        togglePadding={true}
                                        sizeOverride={'w-full'}
                                        backSizeOverride={'w-full card-ratio'}
                                        authorSizeOverride={'text-xs sm:text-sm lg:text-base'}
                                        titleSizeOverride={'text-xs sm:text-sm lg:text-lg'}
                                        ctaSizeOverride={'p-1 py-1 sm:py-4 text-xs md:text-base leading-none'}
                                        cardRatio = 'card-ratio'
                                    />
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
}