import './tailwind.css';

import BaseWrap from './BaseWrap';
import TheProblem from './TheProblem';
import WhatWereDoing from './WhatWereDoing';
import FallingUp from './FallingUp';
import WhoWeAre from './WhoWeAre';
import FAQs from './FAQs';
import Organizations from './Organizations';
import TakeAction from './TakeAction';
import Footer from './Footer';
import Hero from './Hero';
import BooksRow from './BooksRow';
import MapView from './MapView';
import { LocationProvider } from './LocationContext';
import MapViewDetail from './MapViewDetail';
import LocationDebugger from './LocationDebugger';
import Header from './Header';

function App() {
  return (
    <div className="App overflow-x-hidden relative">
        <Header />
        <Hero id="hero" />
        <LocationProvider>
          <BooksRow />
          <MapViewDetail />
          <TheProblem />
          <BaseWrap className={`pt-0 pb-0`} styleProps={{paddingTop:0,backgroundImage:'url("/background-texture.jpg"',backgroundSize:"contain"}} innerClassName={`grid max-w-[none!important]`} blockFeatures>
            <MapView />
          </BaseWrap>
          {window.location.search.indexOf('debug') > 0 && <LocationDebugger />}
        </LocationProvider>
        <WhatWereDoing />
        <WhoWeAre />
        <TakeAction />
        <Organizations />
        <FAQs />
        <Footer />
    </div>
  );
}

export default App;
