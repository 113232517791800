import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import btnPlay from './assets/btnPlay.png'

const Hero = ({ className, innerClassName, id }) => {
    const [vidId, setVidId] = useState(window.innerWidth < 768 ? '845956166' : '845957027')
    const [vidStyle, setVidStyle] = useState(window.innerWidth < 768 ? {padding:"177.78% 0 0 0", position: "relative"} : {padding:"56.25% 0 0 0", position:"relative"})
    const [clickVideo, setClickVideo] = useState(false)
    const resizeFunction = () => {
        if(window.innerWidth < 768) {
            setVidId('845956166')
            setVidStyle({padding:"177.78% 0 0 0", position: "relative"})
        } else {
            setVidId('845957027')
            setVidStyle({padding:"56.25% 0 0 0", position:"relative"})
        }
    }
    useEffect(() => {
        window.addEventListener('resize', resizeFunction)
        return () => {
            window.removeEventListener('resize', resizeFunction)
        }
    })
    const toggleClick = () => {
        resizeFunction()
        setClickVideo(true)
        window.__toggleVideo(setClickVideo)
    }

    return (
        <div className='min-h-screen flex bg-black bg-noise relative mt-[65px]'>
            <div className={`${clickVideo ? '' : 'opacity-0'} min-w-[100vw]`} style={clickVideo ? vidStyle : {} }><iframe src={`https://player.vimeo.com/video/${vidId}?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`} frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen style={{position:"absolute",top:0,left:0,width:"100%",height:"100%"}} title="DPLA_BannedBookClub-Redux-rc26_1"></iframe></div>
            {!clickVideo && 
                <div className='absolute inset-0'>
                    <div className="w-full h-full bg-top bg-contain bg-no-repeat hidden md:flex items-center" style={{backgroundImage:`url("/desktop-landing.jpg")`}}>
                        <div className="aspect-[1920/1080] w-full max-w-[1257px] max-h-[min(1080px,80vh)] relative flex items-end m-auto">
                            <img role='button' src={btnPlay} alt="Play Video" className='ml-4 mb-4 w-12' onClick={() => toggleClick()} />
                        </div>
                    </div>
                    <div className="relative w-full h-full bg-top bg-contain bg-no-repeat md:hidden" style={{backgroundImage:`url("/mobile-landing.jpg")`}}>
                        <div className="relative flex items-end w-full max-w-[400px] max-h-[min(704px,90vh)] aspect-[1080/1920] m-auto">
                            <img role='button' src={btnPlay} alt="Play Video" className='mb-4 ml-4 w-12' onClick={() => toggleClick()} />
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}


export default Hero;