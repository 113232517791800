import React from 'react'
import BaseWrap from './BaseWrap'
import ICOIG from './assets/ico-ig-2.svg'
import ICOTW from './assets/ico-tw-2.svg'
import ICOFB from './assets/ico-fb-2.svg'

export default _ => 
<BaseWrap className={``} innerClassName={`min-h-[80vh] lg:min-h-[unset!important] items-center grid`}  styleProps={{backgroundImage:'url("/background-texture.jpg"',backgroundSize:"contain"}}>
    <div className='max-w-[40rem] no-pad'>
        <div className="ff-pilat-wide text-lg md:text-xl lg:text-2xl mb-4 lg:mb-8 uppercase lg:text-center">Take Action</div>
        <div className="ff-pilat uppercase text-base-2 md:text-[1.2rem] lg:text-lg mb-4 lg:mb-8 lg:text-center leading-none">JOIN OUR CAUSE BY REPORTING A 
BOOK BAN OR DONATING DIRECTLY TO THE DPLA</div>
        <div className="grid place-items-center mb-4 lg:mx-10">
            <a href={`https://forms.gle/BZeGGW4u8JiXwgtG8`} className="a-extra-b ff-pilat-wide text-base grid place-items-center bg-black c-green w-full uppercase py-4">
                    Report a book ban
                </a>
        </div>
        <div className="grid place-items-center mb-4 lg:mx-10">
            <a href='https://nonprofit.resilia.com/donate?id=9847f08ebd8299a38a0b35565a97f41b50e6540c2207563a6040f88c86755dd8' target={`_blank`} className="a-extra-b ff-pilat-wide text-base grid place-items-center bg-black c-green w-full uppercase py-4">
                    Donate
                </a>
        </div>
        <div className='flex justify-center px-2 gap-8 mt-12'>
            <a target="_blank" href="https://www.instagram.com/digpublib/"><img src={ICOIG} width={40} height={40} className='rounded rounded-full' alt="instagram" /></a>
            <a target="_blank" href="https://twitter.com/dpla"><img src={ICOTW} width={40} height={40} className='rounded rounded-full' alt="twitter" /></a>
            <a target="_blank" href="https://www.facebook.com/digitalpubliclibraryofamerica"><img src={ICOFB} width={40} height={40} className='rounded rounded-full' alt="facebook" /></a>
        </div>
    </div>

</BaseWrap>