import React, {useEffect, useRef, useState} from "react";

export default (props) => {
    const [initAllowLocation, setInitAllowLocation] = useState(null);
    const [display, setDisplay] = useState("none");
    const [isGif, setIsGif] = useState(false)

    useEffect(() => {
        setInitAllowLocation(props.didAllowLocation);
    },[])

    useEffect(() => {
        console.log(props.didAllowLocation)
        if (parseInt(props.didAllowLocation)) {
            if (initAllowLocation !== props.didAllowLocation) {
                setDisplay("block")
                setIsGif(true)
                setTimeout(() => {
                    setDisplay("none");
                },1500)
            }
        } else {
            setIsGif(0);
            setDisplay("block")
        }
    }, [props.didAllowLocation]);

    return(
        <div className="click-on relative cursor-pointer">
            <div>
                <div className="cover-overlay w-full h-full absolute flex items-center justify-center lock-scroll" style={{zIndex:1, display:display}}>
                    <img src={'./glitch.png'} alt="" className={'video-overlay ' + (isGif ? 'hidden' : '')}/>
                    <img src={'./glitch.gif'} alt="" className={'video-overlay ' + (isGif ? '' : 'hidden')}/>
                </div>
                
                {props.children}
            </div>
        </div>
    )
}