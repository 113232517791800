import React, { useEffect, useRef } from 'react'
import BaseWrap from './BaseWrap'
import gsap from 'gsap'
import ScrollTrigger from "gsap/ScrollTrigger"
import titles from './titles.json'

export default _ => {
    gsap.registerPlugin(ScrollTrigger)
    const ref = useRef(null)
    const tl = useRef(null)

    useEffect(() => {
        if(tl.current) { tl.current.kill() }
        tl.current = gsap.timeline({
            scrollTrigger: {
                markers: false,
                trigger: "#whatWereDoingContent",
                pin: true,
                start: "top top",
                end: `+=${window.innerHeight}px`,
                scrub: 2,
            }
        });

        tl.current.addLabel("start")
            .to('#whatWereDoingContent .redacted2, #whatWereDoingContent .redacted4', { 
                webkitClipPath: 'inset(0% 105% 0% 0%)',
                clipPath: 'inset(0% 105% 0% 0%)',
                stagger: 0.1
             })
             .to('#whatWereDoingContent .book:nth-child(1)', {
                transform: 'rotate(0deg)',
                opacity:1

            })
            .to('#whatWereDoingContent .book:nth-child(2)', {
                transform: 'rotate(0deg)',
                opacity:1

            })
            .to('#whatWereDoingContent .book:nth-child(3)', {
                transform: 'rotate(0deg)',
                opacity:1

            })
            .to('#whatWereDoingContent .book:nth-child(4)', {
                transform: 'rotate(0deg)',
                opacity:1

            })
            .to('#whatWereDoingContent .book:nth-child(5)', {
                transform: 'rotate(0deg)',
                opacity:1

            })
            .to('#whatWereDoingContent .book:nth-child(6)', {
                transform: 'rotate(0deg)',
                opacity:1

            })
            .to('#whatWereDoingContent .book:nth-child(7)', {
                transform: 'rotate(0deg)',
                opacity:1

            })
            .to('#whatWereDoingContent .book:nth-child(8)', {
                transform: 'rotate(0deg)',
                opacity:1

            })
            .addLabel("hide")
            .to('#whatWereDoingContent .redact2', { alpha: 0 })
            .addLabel("end");
        return () => {
            if(tl.current) { tl.current.kill() }
        }
    }, [ref])

    return (
        <BaseWrap id="whatWereDoingContent" fwdRef={ref} className={''} styleProps={{backgroundImage:'url("/background-texture.jpg"',backgroundSize:"contain"}}>
            <div className=''>

                <div className="relative mb-4 lg:mb-8">
                    <div className="ff-pilat-wide text-lg md:text-xl lg:text-2xl uppercase">What We're Doing</div>
                    <div className="absolute top-0 left-0 redacted2 ff-pilat-wide text-lg md:text-xl lg:text-2xl uppercase">What We're Doing</div>
                    <div className="absolute top-0 left-0 ff-pilat-wide text-lg md:text-xl lg:text-2xl uppercase">What We're Doing</div>
                </div>
                <div className="relative mb-4">
                    <div className="ff-pilat uppercase text-base-2 md:text-[1.2rem] lg:text-lg leading-none">We're putting banned books back in the hands of America</div>
                    <div className="absolute top-0 left-0 redacted2 ff-pilat uppercase text-base-2 md:text-[1.2rem] lg:text-lg leading-none">We're putting banned books back in the hands of America</div>
                    <div className="absolute top-0 left-0 ff-pilat uppercase text-base-2 md:text-[1.2rem] lg:text-lg leading-none">We're putting banned books back in the hands of America</div>
                </div>
                <div className="relative mb-4">
                    <div className='ff-spacemono lg:text-base [1.4rem]'>Last year, thousands of books were banned at libraries, schools and universities.</div>
                    <div className='absolute top-0 left-0 redacted2 ff-spacemono lg:text-base [1.4rem]'>Last year, thousands of books were banned at libraries, schools and universities.</div>
                    <div className='absolute top-0 left-0 ff-spacemono lg:text-base [1.4rem]'>Last year, thousands of books were banned at libraries, schools and universities.</div>
                </div>
                <div className="relative mb-4">
                    <div className="ff-pilat uppercase text-base md:text-[1.2rem] lg:text-lg c-gray"><span className='bg-black'>Our mission is to provide ANYONE WHO IS IN A LIBRARY THAT HAS BANNED A BOOK ACCESS TO THE DIGITAL VERSION FOR FREE.</span></div>
                    <div className="absolute uppercase top-0 left-0 redacted4 ff-pilat text-base md:text-[1.2rem] lg:text-lg c-gray"><span className='bg-black'>Our mission is to provide ANYONE WHO IS IN A LIBRARY THAT HAS BANNED A BOOK ACCESS TO THE DIGITAL VERSION FOR FREE.</span></div>
{/*                     <div className="absolute top-0 left-0 ff-pilat text-base md:text-[1.2rem] lg:text-lg c-gray"><span className=''>Our mission is to provide ANYONE WHO IS IN A LIBRARY THAT HAS BANNED A BOOK ACCESS TO THE DIGITAL VERSION FOR FREE.</span></div> */}
                </div>
                <div className="relative">
                    <div className='ff-spacemono lg:text-base [1.4rem]'>Every time a book is banned from a library, we're going to help put it right back.</div>
                    <div className='absolute top-0 left-0 redacted2 ff-spacemono lg:text-base [1.4rem]'>Every time a book is banned from a library, we're going to help put it right back.</div>
                    <div className='absolute top-0 left-0 ff-spacemono lg:text-base [1.4rem]'>Every time a book is banned from a library, we're going to help put it right back.</div>
                </div>
            </div>
            <div className="falling-up grid grid-cols-8 gap-3 lg:gap-5 lg:mt-6 no-pad">
                {
                    titles.map((title, i) => {
                        return(
                            <div key={i} className="book bg-noise" style={{width:"100%"}}><img src={'./titles/' + title.img + '.png'} className=' w-full' alt={title.title} /></div>
                        )
                    })
                }
            </div>
        </BaseWrap>
    )
}
